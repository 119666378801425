import React from "react"
import Seo from "../components/Seo"
import NavigationLayout, { Content } from "../components/layout/mainLayout"
import { graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { rhythm } from "../utilities/typography"
import rendererOptions from "../utilities/rendererOptions"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"

const AboutPage = ({
  data: {
    contentfulEinfacheSeite: { title, content },
  },
}) => {
  const rendered = renderRichText(content, rendererOptions)

  return (
    <NavigationLayout>
      <Seo title="Über Uns" />
      <Content>
        <Container
          maxWidth={"md"}
          css={`& > *:first-child { margin-bottom: ${rhythm(1)}`}
        >
          <Typography variant={"h1"}>{title}</Typography>
          {rendered}
        </Container>
      </Content>
    </NavigationLayout>
  )
}

export const query = graphql`
  query about {
    contentfulEinfacheSeite(urlTitle: { eq: "geschichte" }) {
      title
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            __typename
            gatsbyImageData
          }
          contentful_id
          title
          fixed {
            src
          }
        }
      }
    }
  }
`

export default AboutPage
